import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Button } from '../components/button.component';
import { MenuLayout } from '../components/menu-layout.component';
import { LoadingSpinner } from '../components/loading.spinner.component';
import { ContactForm } from '../components/contact-form.component';
import { Footer } from '../components/footer.component';
import useControlledLink from '../hooks/use-controlled-link.hook';
// Step svgs
import Step1 from '../assets/svgs/pcr-test-steps/step1.svg';
import Step2 from '../assets/svgs/pcr-test-steps/step2.svg';
import Step3 from '../assets/svgs/pcr-test-steps/step3.svg';
import Step4 from '../assets/svgs/pcr-test-steps/step4.svg';
import Step5 from '../assets/svgs/pcr-test-steps/step5.svg';
import Feature5 from '../assets/svgs/pcr-features/feature5.svg';
import Feature7Group from '../assets/svgs/pcr-features/feature7-group.svg';
import Feature8Hospital from '../assets/svgs/pcr-features/feature8-hospital.svg';
import ShoppingCart from '../assets/svgs/shoppingcart.svg';

export default function Home() {
  const { t, navigate } = useI18next();
  const [isLoading, setIsLoading] = useState(false);
  const navPath = useControlledLink();
  const [ code ] = useQueryParam('c');
  
  useEffect(() => {
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1500);
  }, []);

  useEffect(() => {
    if (code) {
      window.localStorage.setItem('prefill', code);
      navigate('/account/register');
    }
  }, [code]);

  

  return <MenuLayout disableSecureLayout={true} isAbs={true} className="homepage transparent">
    <LoadingSpinner loading={isLoading} />
    <section className="homepage__hero">
        <div className="container">
          <div className="product-headings">
            <p className="t-sans t-green product-headings__title3">
              <span className="clock-icon">
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.50002 2V3.06017C12.1602 3.56017 15 6.69998 15 10.5C15 14.6398 11.6398 18 7.50002 18C3.36021 18 0 14.6398 0 10.5C0 6.69998 2.83981 3.56017 6.50002 3.06017V2H5.50001V0H9.50002V2H8.50002ZM2 10.5C2 13.5398 4.46021 16 7.50002 16C10.5398 16 13 13.5398 13 10.5C13 7.46018 10.5398 4.99997 7.50002 4.99997C4.46021 4.99997 2 7.46018 2 10.5ZM0.0204403 3.12028L0.0204005 3.12024L0.0203805 3.12034L0.0204403 3.12028ZM1.44072 4.52024L0.0204403 3.12028C1.00023 2.12023 2.17981 1.33976 3.47979 0.799934L4.24073 2.64056C3.18057 3.0804 2.24073 3.69992 1.44072 4.52024ZM14.8798 3.02034C13.9001 2.06018 12.7798 1.32034 11.5204 0.799934L10.7603 2.63978C11.7806 3.0601 12.7001 3.6601 13.4807 4.43978L14.8798 3.02034ZM6.79434 9.79148L8.20841 11.2055L11.2068 8.20713L9.79275 6.79307L6.79434 9.79148Z" fill="#E0855E"/>
                </svg>
              </span>
              最速24時間
            </p>
            <h1 className="t-heading1 product-headings__title2">豊泉家PCR検査センター</h1>
            <p className="t-sans product-headings__title1 t-grey7">
              病院・クリニックで実施されている<br/>
              新型コロナウイルスPCR検査を自宅・職場で
            </p>
            <Button className="product-headings__button shopping-cart" href="#shopping-section"><ShoppingCart/> 検査のお申し込み</Button>
          </div>
          <div className="product-image">
          </div>
        </div>
    </section>
    <section className="homepage__features">
      <div className="container">
        <div className="features-block t-sans">
          <div className="feature">
              <div className="feature__icon"><Feature8Hospital /></div>
              <div className="feature__detail">
                  <h3 className="feature__heading t-green">信頼いただける検査</h3>
                  <p className="feature__body t-p1 t-grey7">医療施設内の臨床検査室で検査を行い、地方自治体でも利用されているPCR検査ですので安心です。</p>
              </div>
          </div>
          <div className="feature">
              <div className="feature__icon">
                <svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="27.4792" cy="26.5" r="26.5" fill="#F0EBDF"/>
                  <path d="M38.4459 15.915C34.1465 12.5753 28.1006 19.4526 28.1006 19.4526C28.1006 19.4526 22.0546 12.5753 17.7552 15.915C8.73848 22.9159 28.1006 37.5265 28.1006 37.5265C28.1006 37.5265 47.4626 22.9159 38.4459 15.915Z" fill="#E0855E"/>
                </svg>
              </div>
              <div className="feature__detail">
                  <h3 className="feature__heading t-green">アフターフォロー</h3>
                  <p className="feature__body t-p1 t-grey7">発生届対象者が万が一陽性だった場合は、医師の電話診療や保健所への届出を行いますので、安心です。</p>
              </div>
          </div>
          <div className="feature">
              <div className="feature__icon"><Feature5 /></div>
              <div className="feature__detail">
                  <h3 className="feature__heading t-green">マイページ</h3>
                  <p className="feature__body t-p1 t-grey7">マイページで検査の進捗状況や過去の検査結果などご覧いただけます。結果もメールで通知します。</p>
              </div>
          </div>
      </div>
      </div>
    </section>
    <section className="homepage__test-flow">
      <h2 className="t-h2 homepage__test-flow__heading">{t('PCR test flow')}</h2>
      <div className="test-flow-container">
        <div className="test-flow test-flow--1">
          <div className="test-flow__image">
            <Step1 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">検査キットのお届け</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">お申し込み後、最短で1〜2日でキットをお届けいたします</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--2">
          <div className="test-flow__image">
            <Step2 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">検査キットの登録</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">検査キット登録ページで検査の登録をしてください</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--3">
          <div className="test-flow__image">
            <Step3 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">{t('Saliva collection')}</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">{t('Follow the online procedure to collect saliva')}</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--4">
          <div className="test-flow__image">
            <Step4 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">{t('Packing and return')}</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">{t('Please pack according to the procedure. Please make a pickup reservation with Sagawa Express')}</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--5">
          <div className="test-flow__image">
            <Step5 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">{t('Result notification')}</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">検体到着後、最短24時間で結果をメールで通知いたします</p>
        </div>
      </div>
    </section>
    <section className="homepage__application" id="shopping-section">
      <div className="container">
        <h2 className="t-grey9 t-bold homepage__application__heading t-h2">PCR検査お申し込み</h2>
        <img className="main-product-image" src="/img/products/product-inside-2.png" />
        <div className="main-product">
          <h3 className="t-serif t-grey9">{t('New coronavirus PCR test')}</h3>
          <p className="t-sans t-grey7 t-bold t-l5 main-product__price">¥13,500（税込）<span className="special-offer-tag t-sans t-orange t-bold">{t('free shipping')}</span></p>
          <p className="t-sans t-grey7 t-p1">簡単・安心・安全なPCR検査キットを用いた、自宅での唾液採取検査となります。返送も手軽にでき、同封しているレターパックで近くのポストから投函するだけです。検査結果は検体到着後72時間以内にメールでご報告させていただきます。</p>
          <div className="corporations__application__btn-container">
            <Button className="shopping-cart" href="https://sf-housenka.org/ec/2021/04/01/pcr-testing/" target="_blank"><ShoppingCart/> 検査のお申し込み</Button>
          </div>
        </div>
      </div>
    </section>
    <section className="homepage__additional">
      <div className="container">
        <h3 className="t-heading2 t-serif t-grey9 t-bold homepage__additional__heading">{t('Additional options')}</h3>
        <div className="additional-products">
          <div className="additional-product">
            <h4 className="t-product-name t-serif additional-product__name">クイック結果（最短24時間）</h4>
            <p className="t-sans t-grey7 additional-product__price">＋ ¥16,500（税込）<span className="special-offer-tag t-sans t-orange t-bold">{t('option')}</span></p>
            <p className="t-sans t-grey7 additional-product__description t-p1">速達で検査キットお届け・検体の返送となります。検査センターに検体到着後、最優先に検査いたします。検査結果は、検体到着後即日～24時間にメールでご報告させていただきます。</p>
            <Button className="shopping-cart option" href="https://sf-housenka.org/ec/2021/04/01/pcr-option-1/" target="_blank" rel="noopener noreferrer"><ShoppingCart/> カートにいれる</Button>
          </div>
          <div className="additional-product">
            <h4 className="t-product-name t-serif additional-product__name">国内向け陰性証明書発行</h4>
            <p className="t-sans t-grey7 additional-product__price">＋ ¥5,000 ~（税込 ） <span className="special-offer-tag t-sans t-orange t-bold">{t('option')}</span></p>
            <p className="t-sans t-grey7 additional-product__description t-p1">陰性証明書発行を希望される方への追加サービスとなります。陰性を確認後、グループ医療機関の医師がPDFファイルで陰性証明書（日本語版・英語版）を発行いたします。</p>
            <Button className="shopping-cart option" href="https://sf-housenka.org/ec/2021/04/01/pcr-option-2/" target="_blank" rel="noopener noreferrer"><ShoppingCart/> カートにいれる</Button>
          </div>
        </div>
      </div>
    </section>
    <section className="homepage__whyimportant">
      <div className="container">
        <h2 className="t-heading1 homepage__whyimportant__heading">新型コロナウイルスをうつさない</h2>
        <p className="t-serif t-bold homepage__whyimportant__subtitle">（PCR検査がなぜ重要なのか？）</p>
        <div className="reasons">
          <div className="reason">
            <img src="/img/family-picture.jpg" alt="family picture"/>
            <h4 className="t-h3 t-serif">大切な人のために</h4>
            <p className="t-sans t-grey7 t-p1">高齢の方や基礎疾患をお持ちの方は、新型コロナウイルスに感染すると重症化する危険度が高いとされています。無症状・軽症の方でも感染させてしまう可能性がありますので、自分が感染しているかを知ることは重要なことです。</p>
          </div>
          <div className="reason">
            <img src="/img/doctor-picture.jpg" alt="doctor picture"/>
            <h4 className="t-h3 t-serif">安心できる生活を送るために</h4>
            <p className="t-sans t-grey7 t-p1">日常生活において必要な職業についている方々が安心して生活を送るためには、新型コロナウイルスの感染の有無を早期に発見する事が大切です。そのためにも、社会全体で協力をして「うつさない」行動をとることが重要です。</p>
          </div>
        </div>
      </div>
    </section>
    <section className="homepage__faq">
      <div className="container">
        <h2 className="t-heading1">よくある質問</h2>
        <div className="faqs">
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">症状があっても受けられますか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">
            以下の症状がある方は、郵送PCR検査を受けずにただちに指定医療機関と都道府県の相談窓口へお問い合わせいただき受診の相談をして下さい。それ以外の方で軽症・無症状の方は、どなたでも郵送PCR検査を受けていただけます。<br/><br/>
            <ol>
              <li>重篤な症状（呼吸が苦しい、息切れ、胸の痛みなど）がある方</li>
              <li>ご高齢（65歳以上）または基礎疾患（糖尿病、高血圧、がん、心疾患、呼吸器疾患、肝疾患、腎疾患、透析を行っているなど）がある方で新型コロナウイルス感染症を疑う症状（発熱、咳、倦怠感、息苦しさ、嗅覚異常など）がある方</li>
            </ol>
            </p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">検査キットはどのくらいで届きますか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">
              お申し込みから最短で1～2日でお届けします。<br/>
              大阪より遠方の地域へ発送する場合は数日かかる場合がございます。</p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">検査結果は、いつ頃わかりますか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">検査場に検体が到着してから72時間以内に、検査結果通知をメールでお知らせいたします。また、マイページでも検査状況をご確認いただけます。<br/>検査スケジュールについては、<a className="t-p1 t-grey7 faq__answer__link" style={{ textDecoration: 'underline' }} href="/docs/pcr_letterpack.pdf" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。</p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">土日や祝日も発送や検査をしていますか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">日曜日と祝日は発送や検査は行なっておりません。検査キットの発送は、月～土曜日に行なっております。返送された検体のPCR検査は、月～金曜日に行っております。</p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">複数人分まとめてのPCR検査申し込みは可能ですか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">はい、PCR検査のお申し込みは、複数人分のお申し込みいただけます。<br/>
              法人のお客様で団体（10名以上）でのお申し込みをされる場合は、「法人の方」ページよりお申し込みください。</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button to="/faqs">もっと見る</Button>
          </div>
        </div>
      </div>
    </section>
    <section className="homepage__contact" id="contact-us">
      <div className="container">
        <div className="contact-bg">
          <h2 className="t-heading2 t-serif t-bold">{t('Contact Us')}</h2>
        </div>
        <h2 className="t-heading2 t-serif t-bold">{t('Contact Us')}</h2>
        <ContactForm />
      </div>
    </section>
    <Footer />
  </MenuLayout>;
}
