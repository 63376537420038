import { useEffect, useState } from 'react';

import { useUser } from './use-user.hook';

export default function() {
    const [registerPath, setRegisterPath] = useState('/account/register');
    const { loggedIn } = useUser();

    useEffect(() => {
        if (loggedIn) {
            setRegisterPath('/register');
        }
    }, [loggedIn]);

    return registerPath;
}